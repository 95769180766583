<template>
  <PropertiesList/>
</template>

<script>
export default {
  name: 'properties',
  components: {
    PropertiesList: () => import('@/components/properties/PropertiesList')
  }
}
</script>